.main-content {
  background-color: #fdfafa;
  padding: 64px 48px;
  line-height: 1.5;

  &__image {
    margin-bottom: -32px;
  }

  &__title {
    font-family: "GTWalsheim", Helvetica, Arial, sans-serif;
    font-size: 36px;
    font-weight: 800;
    color: #000;
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__list-item {
    position: relative;
    padding-left: 16px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #000;
      left: 0;
      top: 6px;
    }
  }
}

@media (min-width: 768px) {
  .main-content {
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .main-content {
    background-color: unset;
  }
}
