/* GothamSSm */

@font-face {
    font-family: "GothamSSm";
    src: url("./assets/fonts/GothamSSm-Light.woff") format("woff"),
        url("./assets/fonts/GothamSSm-Light.otf") format("opentype");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "GothamSSm";
    src: url("./assets/fonts/GothamSSm-LightItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "GothamSSm";
    src: url("./assets/fonts/GothamSSm-Book.otf") format("opentype");
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

/* GT Walsheim */

@font-face {
    font-family: "GTWalsheim";
    src: url("./assets/fonts/GT-Walsheim-Bold.otf") format("opentype");
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "GTWalsheim";
    src: url("./assets/fonts/GT-Walsheim-Black.otf") format("opentype");
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

html,
body {
    font-size: 16px;
    font-weight: 300;
    font-family: "GothamSSm",
        Helvetica,
        Arial,
        sans-serif;
}

.page {
    display: flex;
    flex-direction: column;

    &__main-content {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .page__main-content {
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .page__main-content {
        max-width: 1200px;
        margin: 0 auto;
    }
}
