.footer {
    display: flex;
    flex-direction: column;
    background-color: #232328;
    padding: 16px;
    width: 100%;

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &--top {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(#FAFAFA, .6);
            position: absolute;
            bottom: 0;
        }
    }

    &--bottom {
        padding-top: 48px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 16px;

        .footer__link {
            font-size: 10px;

            & + .footer__link {
                margin-left: 8px;

                &::before{
                    content: "|";
                    margin-right: 8px;
                }
            }
        }
    }

    &__column {
        flex-basis: 100%;
        margin-bottom: 48px;
    }

    &__sub-header {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
    }

    &__link {
        color: #fff;
        margin: 8px 0;
        display: inline-flex;
        line-height: 16px;
    }

    &__icon-link {
        margin: 0 20px;
    }

    &__emphasized-text {
        color: #fff;
        font-size: 12px;
        line-height: 12px;
    }

    &__copyright-text {
        display: block;
        color: #fff;
        font-size: 10px;
    }
}

@media (min-width: 576px) {
    .footer {
        &--top {
            flex-direction: row;
        }

        &__column {
            flex-basis: 50%;
        }
    }
}

@media (min-width: 768px) {
    .footer {
        padding: 24px;

        &__column {
            flex-basis: calc(100% / 3);
        }
    }
}

@media (min-width: 992px) {
    .footer {
        padding: 48px;
    }
}

@media (min-width: 1200px) {
    .footer {
        &__column {
                flex-basis: calc(100% / 6);
            }

        &__wrapper {
            width: 1200px;
            max-width: 1200px;
            margin: 0 auto;
        }

        &--bottom {
            flex-direction: row;
            justify-content: space-between;
        }

        &__icon-link--last {
            margin-right: 0;
        }
    }
}