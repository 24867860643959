.form-wrapper {
  padding: 48px 48px 64px 48px;

  &__image {
    margin-bottom: 64px;
    max-width: 70%;
  }

  &__title {
    font-family: "GTWalsheim", Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .form {
    &__row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      position: relative;
    }

    &__label {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    &__input {
      border-radius: 4px;
      border: 1px solid #232328;
      line-height: 44px;
      padding: 0 16px;

      &:hover {
        box-shadow: inset 0px 0px 0px 1px #232328;
      }

      &:focus {
        border-color: #288bed;
        box-shadow: inset 0px 0px 0px 1px #288bed;
      }
    }

    &__button {
      box-sizing: border-box;
      line-height: 44px;
      padding: 0 16px;
      background-color: #232328;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 400;
      margin-top: 16px;

      &:hover:not(:disabled) {
        background-color: #fff;
        box-shadow: inset 0px 0px 0px 3px #232328;
        color: #232328;
      }

      &:disabled {
        opacity: 0.9;
      }

      &:disabled:hover {
        cursor: default;
      }
    }

    &__error {
      display: block;
      position: absolute;
      font-size: 10px;
      color: red;
      bottom: -14px;
    }
  }
}

@media (min-width: 768px) {
  .form-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

// For loading spinner
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 5px;
  top: -1px;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
